/* $(function() {
    var showChar = 250;  // How many characters are shown
    var ellipsestext = "...";
    var moretext = "<p class=\"my-2 font-weight-bold\">Lire la suite</p>";
    var lesstext = "<p class=\"my-2  font-weight-bold\">Voir moins</p>";  
  
    $('.js-read-more').each(function() {
      var content = $(this).html();
  
      if(content.length > showChar) {
        var html;
        var c = content.substr(0, showChar); // less text
        var h = content.substr(showChar, content.length - showChar); // more text
  
        html = c;
        html += '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span>';
        html += '<span class="morecontent">' + h + '</span>';
        html += '<a href="" class="morelink">' + moretext + '</a>';
        
        $(this).html(html);
        $(this).find('.morecontent').hide();
      }
    });
  
    $(".morelink").on('click', function(e){
      e.preventDefault();
      if($(this).hasClass("less")) {
        $(this).removeClass("less");
        $(this).html(moretext);
      } else {
        $(this).addClass("less");
        $(this).html(lesstext);
      }
      $(this).parent().find('.moreellipses').toggle();
      $(this).parent().find('.morecontent').toggle();
    });
  }); */

  $(document).ready(function() {
    // On crée la balise more après le premier paragraphe
    var firstParagraphe = $('.js-read-more p:first');
    $('<span id="more" style="display: none;"></span>').insertAfter(firstParagraphe);
  
    // On ajoute le reste dans la balise more
    var contentWithoutfirst = $('#more').nextAll();
    $('#more').append(contentWithoutfirst);
  
    if(!$('#more').is(':empty')){
      $(".morelink" ).click(function(){
        var moreText = $("#more");
        var btnText = $(".morelink");
  
        if ($("#more:visible").length > 0) {
          btnText.html('<p class="my-2 font-weight-bold text-center text-underline">Lire la suite</p>');
          moreText.css('display','none');
        } else {
          btnText.html('<p class="my-2 font-weight-bold text-center text-underline">Voir moins</p>');
          moreText.css('display','inline');
        }
      });
    } else {
      $('.morelink').hide();
    }  
  });