// Import Swiper and modules
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';

import "swiper/swiper.scss"

// Install modules
SwiperCore.use([Autoplay, Navigation, Pagination]);

const ourEssentialsSwiper = new SwiperCore('.js-swiper-essentials', {
    // Default parameters
    slidesPerView: 1.5,
    spaceBetween: 20,
    breakpointsInverse: true,
    slidesPerColumn: 1,
    slidesPerColumnFill: 'row',
    navigation: {
        nextEl: '.js-category-next-button',
        prevEl: '.js-category-prev-button',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
    },
    // Responsive breakpoints
    breakpoints: {
        576: {
            slidesPerView: 1.3,
            spaceBetween: 10,
            slidesPerColumn: 1,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 10,
            slidesPerColumn: 1,
        }
    }
});
const YouMightAlsoLikeSwiper = new SwiperCore('.js-swiper-alsoLike', {
    // Default parameters
    slidesPerView: 2.3,
    spaceBetween: 0,
    breakpointsInverse: true,
    slidesPerColumn: 1,
    slidesPerColumnFill: 'row',
    loop: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    // Responsive breakpoints
    breakpoints: {
        576: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1800: {
            slidesPerView: 3,
            spaceBetween: 100,
        }
    }
});
const swiperCrosselling = new SwiperCore('.js-swiper-crosselling', {
    // Default parameters
    slidesPerView: 2.3,
    spaceBetween: 0,
    breakpointsInverse: true,
    slidesPerColumn: 1,
    slidesPerColumnFill: 'row',
    navigation: {
      prevEl: '.swiper-prev-crosselling',
      nextEl: '.swiper-next-crosselling',
    },
    // Responsive breakpoints
    breakpoints: {
        576: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1800: {
            slidesPerView: 3,
            spaceBetween: 100,
        }
    }
});

const swiperSliderHp = new SwiperCore('.js-swiper-slider', {
  // Optional parameters
  spaceBetween: 30,
  loop: true,
  autoplay: true,
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
