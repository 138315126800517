let ourEssentialsSection = document.getElementById('our-favorites');
let ourBestSellers = document.querySelector('#our-favorites .featured-products.our-bestsellers');
let ourNewProducts = document.querySelector('#our-favorites .featured-products.our-new-products');
let ourBestSellersBtn = document.querySelector('#our-favorites .our-bestsellers-btn.btn');
let ourNewProductsBtn = document.querySelector('#our-favorites .our-new-products-btn.btn');

if(ourNewProductsBtn){
  ourNewProductsBtn.addEventListener("click", function(e) {
      if((ourNewProducts.style.display === "none") || (ourNewProducts.style.display === "") ) {
          ourBestSellers.style.display = "none";
          if(ourBestSellersBtn.classList.contains("btn-primary")) {
          ourBestSellersBtn.classList.remove('btn-primary');
          ourBestSellersBtn.classList.add('btn-primary--light');
          }
          ourNewProducts.style.display = "block";
          if(ourNewProductsBtn.classList.contains("btn-primary--light")) {
              ourNewProductsBtn.classList.remove('btn-primary--light');
              ourNewProductsBtn.classList.add('btn-primary');
          }
      }
  })
  
  ourBestSellersBtn.addEventListener("click", function() {
      if((ourBestSellers.style.display === "none") || (ourBestSellers.style.display === "")) {
          ourNewProducts.style.display = "none";
          if(ourNewProductsBtn.classList.contains("btn-primary")) {
              ourNewProductsBtn.classList.remove('btn-primary');
              ourNewProductsBtn.classList.add('btn-primary--light');
          }
          ourBestSellers.style.display = "block";
          if(ourBestSellersBtn.classList.contains("btn-primary--light")) {
              ourBestSellersBtn.classList.remove('btn-primary--light');
              ourBestSellersBtn.classList.add('btn-primary');
          }
      }
  })
}
